import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineEdit, AiOutlineCalendar } from "react-icons/ai";
import styles from "./blogcard.module.scss";

const stripHtmlTags = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
};

const BlogCard = ({ data, allseminar }) => {
  const descWithoutHtml = stripHtmlTags(data.description);
  const desc = descWithoutHtml.slice(0, 200) + "...";

  return (
    <div>
      <div className={styles.blog}>
        <div className={`${styles.thumb} event-img`}>
          <Link to={"/blog/" + data._id}>
            <img src={data.image} alt="blog-img" />
          </Link>
        </div>
        <div className={styles.blogInner}>
          <div
            className={
              allseminar ? `p-3 ${styles.content}` : `${styles.content}`
            }
          >
            <div className="row">
              <div className="col-12">
                <h3 className="m-0 p-0 blog-title mb-2">
                  <Link to={process.env.PUBLIC_URL + "/blog/" + data._id}>
                    {data.title}
                  </Link>
                </h3>
              </div>
            </div>
            <p>
              {" "}
              <AiOutlineCalendar />{" "}
              <span>
                Posted :{" "}
                {new Date(data.createdAt).toLocaleString("en-GB", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </span>
            </p>
            <p>
              {" "}
              {/* <AiOutlineEdit /> <span>Author : {data.author.name}</span> */}
            </p>

            <div className="text-justify my-2 blog-des">{desc}</div>

            <Link
              className={styles.readmoreBtn}
              to={process.env.PUBLIC_URL + "/blog/" + data._id}
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object,
};

export default BlogCard;
