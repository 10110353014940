
import React from "react";
import styles from "./Breadcrumb.module.scss";
import bgImg from "../../../assets/images/bg/5.jpg";

const HomeBreadcrumb = () => {
  return (
    <div
    className={styles.blogBreadcrumbArea}
    // style={{ backgroundImage: `url(${bgImg})` }}
  >
    
  </div>
  );
};


export default HomeBreadcrumb;
